/*
  Scripts specific to Rek-AI implementation. 
*/

interface RekAiResult {
  title: string;
  url: string;
  h1: string;
}

 // Quicksearchs
 var quickSearchFieldIds = ['page-search', 'page-search-tablet', 'page-search-sticky', 'page-search-dw']

 // Other
 var searchPageSearchFieldIds = ['search-query', 'publications-search-query']

 /*
  Gets the search page action url for the form
*/
 export const getFormActionUrl = (id:string) => {
  var searchForm = document.getElementById(id)?.closest('form');
  return searchForm?.getAttribute('action');
}

function getPosition(string: string, subString: string, index: number) {
  return string.split(subString, index).join(subString).length;
}

const getFormSubTree = (id: string) => {
    const searchFormAction = document.getElementById(id)?.closest('form')?.getAttribute('action');
    if(searchFormAction && searchFormAction.split('/').length <= 3)
        return '/'

    if(!searchFormAction)
      return '/'

    return searchFormAction.substring(0, getPosition(searchFormAction, '/', 2));
}

/*
  Setup for rek AI autocomplete
*/
export const setupRekAI = () => {    
  // window.__rekai?.ready(() =>  {
  document.addEventListener("DOMContentLoaded", (e) => {         
      var searchInputs = document.querySelectorAll('input[type="search"]');
      searchInputs.forEach(input => {
        var id = input.getAttribute('id') as string;      
        var rekAutocomplete = window.rekai_autocomplete(`#${id}`, 
          {
            debug: false,
            params: {
                subtree: getFormSubTree(id),
                addcontent: true,
                openOnFocus: true,
            },
          },
              [{
                  templates:
                  {
                      suggestion: function(suggestion: RekAiResult) {
                          return suggestion.h1
                      },
                      empty: function() {
                        return '<div class="rekai-suggestion-empty">Din sökning gav inga träffar</div>';
                      }
                  }
              }]
          )
          .on('rekai_autocomplete:selected', function (event: Event, suggestion: RekAiResult, dataset: number) {
            if(quickSearchFieldIds.includes(id))
            {
                var searchPageUrl = getFormActionUrl(id)
                window.location.href = `${searchPageUrl}?query=${encodeURIComponent(suggestion.title?.toLowerCase())}`
            }
            else if(searchPageSearchFieldIds.includes(id))
            {
                window.location.href = suggestion.url
            }
        });
      })  
    })  
}

setupRekAI();
