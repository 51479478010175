/*
  Scripts specific to CookieBot (https://www.cookiebot.com/) implementation. 

*/

import { domReady } from "../../scripts";

export const showCookieBanner = () => window?.Cookiebot?.show();

/*
  Expands details view in CookieBot panel, with more information on active cookies
*/
export const showCookieBannerDetails = () => {
  window?.Cookiebot?.renew();

  setTimeout(() => {
    const cookieBanner = document.getElementById("CookieBanner");

    if (cookieBanner) {
      const detailsToggle = document.querySelector(
        ".cookiebanner__details-toggle"
      );

      cookieBanner.classList.add("is-details-open");

      if (detailsToggle) {
        detailsToggle.setAttribute("aria-expanded", "true");
        detailsToggle.textContent = detailsToggle.getAttribute("data-details-close");
        cookieBanner.style.overflowY = "scroll";
      }
    }
  }, 300);
};

export const attachShowCookieBannerDetailsEvent = () => {
  const showCookieBannerBtns = document.querySelectorAll(
    ".js-show-cookie-banner-details"
  ) as NodeListOf<HTMLButtonElement>;

  if (showCookieBannerBtns.length > 0) {
    showCookieBannerBtns.forEach((btn) =>
      btn.addEventListener("click", () => {
        if (window.Cookiebot) {
          showCookieBannerDetails();
        }
      })
    );
  }
};

export type CookieConsentCategories =
  | "necessary"
  | "preferences"
  | "statistics";

// Utility function for checking current consent state by category
export const getCookieConsentByCategory = (category: CookieConsentCategories) =>
  window?.Cookiebot?.consent[category];

// Used by ReadSpeaker to highlight CookieBot preference panel
export const highlightPreferencePanel = () => {
  setTimeout(() => {
    const panel = document.getElementById("cookiebanner-preference-pref");

    if (panel) {
      panel.classList.add("cookiebanner__preference--highlight");
    }
  }, 200);
};

domReady(() => {
    attachShowCookieBannerDetailsEvent();
});
